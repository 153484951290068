<template>
  <div class="list-item-root-container">
    <div class="list-item-header-container">
      <span style="font-size: 15px; font-weight: bold">{{
        dataSource.task_inventory_name
      }}</span>
      <Button type="primary" size="small" @click="del">删除</Button>
    </div>
    <Progress
      style="margin-top: 10px"
      :percent="percent"
      :stroke-width="20"
      text-inside
    />
    <Button
      size="small"
      style="align-self: flex-start; margin-top: 10px; margin-left: 30px"
      @click="addList"
      >为清单添加组成</Button
    >
    <div class="list-item-children-container">
      <Checkbox
        v-for="item in dataSource.children"
        :key="item.task_inventory_detail_id"
        v-model="item.state"
        @on-change="
          (state) => {
            stateChange(item, state);
          }
        "
      >
        <span
          :style="{ 'text-decoration': item.state ? 'line-through' : '' }"
          >{{ item.task_inventory_detail_name }}</span
        >
      </Checkbox>
    </div>
    <Modal v-model="listModalVisiable" @on-ok="addListComplete">
      <Input
        v-model="listName"
        placeholder="请输入组成名称..."
        style="width: 300px; margin: 30px"
      />
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  props: {
    dataSource: Object,
  },
  data() {
    return {
      listModalVisiable: false,
      listName: "",
      taskId: 0,
    };
  },
  mounted() {
    this.taskId = this.$router.currentRoute.params.tid;
  },
  computed: {
    percent() {
      if (this.dataSource.children.length == 0) {
        return 0;
      }
      let completeCount = this.dataSource.children.filter((item) => {
        return item.state == true;
      }).length;
      return Math.round(
        (completeCount / this.dataSource.children.length) * 100
      );
    },
  },
  methods: {
    ...mapActions({
      taskDelTaskInventoryAction: "taskDelTaskInventory",
      taskAddTaskInventoryDetailAction: "taskAddTaskInventoryDetail",
      taskSetTaskInventoryDetailAction: "taskSetTaskInventoryDetail",
    }),
    stateChange(item, state) {
      this.taskSetTaskInventoryDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
        task_inventory_id: this.dataSource.task_inventory_id,
        task_inventory_detail_id: item.task_inventory_detail_id,
        state: state,
      })
        .then((res) => {
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    del() {
      this.taskDelTaskInventoryAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id:this.taskId,
        task_inventory_id: this.dataSource.task_inventory_id,
      })
        .then((res) => {
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    addList() {
      this.listName = "";
      this.listModalVisiable = true;
    },
    addListComplete() {
      this.taskAddTaskInventoryDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
        task_inventory_id: this.dataSource.task_inventory_id,
        name: this.listName,
      })
        .then((res) => {
          this.$emit("refresh");
          this.listModalVisiable = false;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.list-item-root-container {
  display: flex;
  flex-direction: column;
}
.list-item-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.list-item-children-container {
  margin-left: 30px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
</style>