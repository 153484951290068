<template>
  <div class="task-list-root-container">
    <Button
      style="align-self: flex-start"
      type="default"
      icon="ios-list-box-outline"
      @click="addList"
      >向该任务添加一行清单</Button
    >
    <div class="task-list-item-container">
      <list-item
        v-for="item in list"
        :key="item.task_inventory_id"
        :dataSource="item"
        style="margin-top: 20px"
        @refresh="getList"
      />
    </div>

    <Modal v-model="listModalVisiable" @on-ok="addListComplete">
      <Input
        v-model="listName"
        placeholder="请输入清单名称..."
        style="width: 300px; margin: 30px"
      />
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ListItem from "./Item/ListItem";
export default {
  components: {
    "list-item": ListItem,
  },
  data() {
    return {
      listModalVisiable: false,
      listName: "",
      list: [],
      taskId: 0,
    };
  },
  mounted() {
    this.taskId = this.$router.currentRoute.params.tid;
    this.getList();
  },
  methods: {
    ...mapActions({
      taskGetTaskInventoryListAction: "taskGetTaskInventoryList",
      taskAddTaskInventoryAction: "taskAddTaskInventory",
    }),
    getList() {
      this.taskGetTaskInventoryListAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
      })
        .then((res) => {
          this.list = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    addList() {
      this.listName = "";
      this.listModalVisiable = true;
    },
    addListComplete() {
      this.taskAddTaskInventoryAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
        name: this.listName,
      })
        .then((res) => {
          this.getList();
          this.listModalVisiable = false;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.task-list-root-container {
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
}
.task-list-item-container {
  display: flex;
  flex-direction: column;
}
</style>